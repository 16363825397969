<template>
	<section
		class="hero-wrapper"
		:data-has-background-image="!!backgroundImageUrl"
	>
		<div class="overlay"></div>
		<div v-if="backgroundVideo" class="hero-video-wrapper">
			<video loop muted autoplay playsinline>
				<source :src="backgroundVideo" type="video/mp4" />
			</video>
		</div>
		<PaddingContainer :padding="padding" component="div" class="hero-content">
			<div class="heading-wrapper">
				<Component
					:is="`h${headingLevel}`"
					v-if="heading.text"
					class="heading-font hero-heading"
				>
					{{ heading.text }}
				</Component>
				<span v-if="heading.logoImage" class="hero-heading-logo">
					<img :src="heading.logoImage.url" :alt="heading.logoImage.alt" />
				</span>

				<span v-if="subheading" class="default-font hero-sub-heading">
					{{ subheading.text }}
				</span>
			</div>

			<div class="buttons-wrapper">
				<ButtonBlock
					v-for="(button, index) in buttons"
					:key="`hero-section-button:${index}:${button.text}`"
					v-bind="button"
				/>
			</div>
		</PaddingContainer>
	</section>
</template>

<script setup lang="ts">
import type { HeroSection } from '@SHARED/core/entities/sections/HeroSection';
import type {
	WebsitePage,
	WebsiteStyles
} from '@SHARED/core/entities/WebsiteConfig';
import type { CssSize } from '@SHARED/utils/helperTypes';

import { useFlexPosition } from '@SHARED/composables/useFlexPosition';
import { getCSSColorVar } from '@SHARED/utils/style';

import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';

defineOptions({ name: 'HeroSection' });

const styles = useState<WebsiteStyles>('styles');

const pages = useState<WebsitePage[]>('pages');

const route = useRoute();

const props = withDefaults(defineProps<HeroSection['config']>(), {
	headingLevel: 1,
	height: '100vh',
	overlayOpacity: 0,
	backgroundVideo: '',
	contentPosition: 'center-center',
	gapConfig: () => ({
		content: '2rem',
		text: '2rem'
	})
});

const overlay = computed<string>(() =>
	props.overlayOpacity ? `rgba(0,0,0, ${props.overlayOpacity})` : ''
);

const backgroundColor = computed<string>(() =>
	getCSSColorVar(props?.backgroundColor || styles.value.appearance.background)
);

const textColor = computed<string>(() =>
	getCSSColorVar(props?.textColor || styles.value.appearance.text)
);

type SectionHeight = 'calc(100vh - var(--header-height))' | CssSize;

const sectionHeight = computed<SectionHeight>(() => {
	if (props.height !== '100vh') return props.height;

	const page = pages.value.find(page => page.name === route.name);

	if (!page || page.isHeaderTransparent) return '100vh';

	return 'calc(100vh - var(--header-height))';
});

const headingMaxWidth = computed<CssSize>(
	() => props.heading.maxWidth || '100%'
);

const defaultFontConfig: Record<'mobile' | 'desktop', CssSize> = {
	desktop: '1rem',
	mobile: '1.25rem'
};

const fontSizes = computed<Record<'mobile' | 'desktop', CssSize>>(
	() => props.heading.fontSize || defaultFontConfig
);

const desktopHeadingFontSize = computed<CssSize>(() => fontSizes.value.desktop);

const mobileHeadingFontSize = computed<CssSize>(() => fontSizes.value.mobile);

const defaultLogoImageConfig: Record<'mobile' | 'desktop', CssSize> = {
	desktop: '200px',
	mobile: '100px'
};

const logoImageSizes = computed<Record<'mobile' | 'desktop', CssSize>>(
	() => props.heading?.logoImage?.sizes || defaultLogoImageConfig
);

const mobileHeadingLogoImageSize = computed<CssSize>(
	() => logoImageSizes.value.mobile
);

const desktopHeadingLogoImageSize = computed<CssSize>(
	() => logoImageSizes.value.desktop
);

const { mobile: mobileSubheadingFontSize, desktop: desktopSubheadingFontSize } =
	props.subheading?.fontSize || defaultFontConfig;

const { justifyContent: heroJustifyContent, alignItems: heroAlignItems } =
	useFlexPosition({ position: props.contentPosition, flexDirection: 'column' });

const heroContentPaddingBottom = props.contentPosition.includes('bottom')
	? 'calc(var(--header-height) / 2)'
	: 0;

const heroContentPaddingTop = props.contentPosition.includes('top')
	? 'calc(var(--header-height) * 1.5)'
	: 0;

const $img = useImage();

const backgroundImageUrl = computed<string>(() => {
	if (!props.backgroundImage?.trim()) return '';

	// <!-- TODO: no futuro podemos usar o método $img.getSizes para otimizar mais a imagem, mas no momento a API desse método ainda é instável e pode mudar ou ser removida a qualquer momento -->
	const optimizedImageUrl = $img(props.backgroundImage, { format: 'webp' });

	return `url(${optimizedImageUrl})`;
});

const { content: heroContentGap, text: heroTextGap } = props.gapConfig;
</script>

<style lang="scss" scoped>
.overlay {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: v-bind(overlay);
	width: 100%;
	height: v-bind(sectionHeight);
}

.hero-video-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: v-bind(sectionHeight);
	overflow: hidden;

	video {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@include screen-up(md) {
		display: block;
	}
}

.hero-wrapper {
	display: flex;
	align-items: center;
	background-size: cover;
	background-position: center;
	background-color: v-bind(backgroundColor);
	width: 100%;
	height: v-bind(sectionHeight);
	z-index: 3;
	position: relative;

	&[data-has-background-image='true'] {
		background-image: v-bind(backgroundImageUrl);
	}

	.hero-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: v-bind(heroJustifyContent);
		align-items: v-bind(heroAlignItems);
		gap: v-bind(heroContentGap);
		text-align: center;
		color: v-bind(textColor);
		z-index: 3;
		padding-bottom: v-bind(heroContentPaddingBottom);
		padding-top: v-bind(heroContentPaddingTop);

		.heading-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: inherit;
			align-items: inherit;
			gap: v-bind(heroTextGap);

			.hero-heading {
				font-size: v-bind(mobileHeadingFontSize);
				max-width: v-bind(headingMaxWidth);
				line-height: 125%;

				@include screen-up(lg) {
					font-size: v-bind(desktopHeadingFontSize);
				}
			}
			.hero-heading-logo {
				width: 100%;
				max-width: v-bind(mobileHeadingLogoImageSize);

				@include screen-up(lg) {
					max-width: v-bind(desktopHeadingLogoImageSize);
				}

				img {
					width: 100%;
					height: auto;
				}
			}

			.hero-sub-heading {
				font-size: v-bind(mobileSubheadingFontSize);
				font-weight: 300;

				@include screen-up(lg) {
					font-size: v-bind(desktopSubheadingFontSize);
				}
			}
		}
	}

	.buttons-wrapper {
		display: flex;
		justify-content: inherit;
		align-items: inherit;
		gap: 1rem;
	}
}

// <!-- * Os seletores abaixo são apenas para remover os controles de vídeo no iOS
*::-webkit-media-controls {
	&-panel,
	&-play-button,
	&-start-playback-button {
		display: none !important;
		appearance: none;
		-webkit-appearance: none;
	}
}
</style>
