import type {
	HorizontalPosition,
	VerticalPosition,
	Position
} from '@/core/helpers/Position';

export type FlexOption = 'flex-start' | 'center' | 'flex-end';

export type FlexDirection = 'column' | 'row';

const justifyContentMap: Record<HorizontalPosition, FlexOption> = {
	left: 'flex-start',
	center: 'center',
	right: 'flex-end'
};

const alignItemsMap: Record<VerticalPosition, FlexOption> = {
	top: 'flex-start',
	center: 'center',
	bottom: 'flex-end'
};

type UsePositionReturn = {
	justifyContent: FlexOption;
	alignItems: FlexOption;
};

export function useFlexPosition({
	position,
	flexDirection = 'row'
}: {
	position: Position;
	flexDirection?: FlexDirection;
}): UsePositionReturn {
	const [verticalPosition, horizontalPosition] = position.split('-') as [
		VerticalPosition,
		HorizontalPosition
	];

	const justifyContent = justifyContentMap[horizontalPosition];

	const alignItems = alignItemsMap[verticalPosition];

	return {
		justifyContent: flexDirection === 'column' ? alignItems : justifyContent,
		alignItems: flexDirection === 'column' ? justifyContent : alignItems
	};
}
